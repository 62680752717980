import MANTLE from "../contracts/WMANTLE.json"; 
import ARBITRUM from "../contracts/Arb.json";
import CORE from "../contracts/WCORE.json";
import OKX from "../contracts/OKBImplementation.json";
import OPBNB from "../contracts/WBNB.json";
import PULSECHAIN from "../contracts/WPLS.json";
import WETH from "../contracts/WETH9.json";
import POLYGON from "../contracts/WMATIC.json";
import COINTOKEN from "../contracts/CoinToken.json";
import USDC from "../contracts/USDC.json";
import MANTA from "../contracts/Manta.json";
import WNEON from "../contracts/WNEON.json";

import Battledog from "../contracts/Battledog.json";
import Pop from "../contracts/ProofOfPlay.json";
import Game from "../contracts/GAME.json";
import Harvester from "../contracts/Harvester.json";
import abi from "../contracts/abi.json";

const contractData = {
  battledog: Battledog,
  pop: Pop,
  game: Game,
  harvester: Harvester,
  lp_abi: abi
};


const networkData1 = {
  networkid: 1,
  rpc: "https://enugu-rpc.assetchain.org",
  chainId: 42421,
  hex: "0xA5B5",
  theme: "deepskyblue",
  NATIVE: WETH,
  tokenA: "WRWA",
  tokenB: "GAME",
  coin: 'RWA',
  battledogaddress: "0xaB803C683000C3Ab8836477B0e2BDC45C20139d1",
  nativeaddress: "0x0FA7527F1050bb9F9736828B689c652AB2c483ef",
  gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
  popaddress: "0x96F2B371D800bf32BB89DAd05b61B380030030A5",
  harvesteraddress: "0x1Fa03219ff837C1Dfc629c5A8F834E47594fa1fd",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 3,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: false,
  testnet: true,
  wrap: true,
  excess: "",
  website: "battledogs.fun/assetchain",
  bestover: "10",
  ecosystem: "Assetchain"
};
  
const networkData2 = {
  networkid: 2,
  rpc: "https://pacific-rpc.manta.network/http",
  chainId: 169,
  hex: "0xa9",
  theme: "#33CC99",
  NATIVE: MANTA,
  tokenA: "MANTA",
  tokenB: "GAME",
  coin: 'ETH',
  battledogaddress: "0x9dc5736Db801272B2357962448B189f5A77a5e36",
  nativeaddress: "0x95CeF13441Be50d20cA4558CC0a27B601aC544E5",
  gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
  popaddress: "0xed26059bd0D043133FAa31bF83ca047bB3c299Cb", 
  harvesteraddress: "0x87E34F8ac093c37CeC26Ef3717bdC2e76D12cdaD",
  lpaddress: "0x39588b08Df336B41402F450eC916e700d1C5652C",
  lpharvesteraddress: "0xab079F5Dcac514497f0CB395c5Db61C80036c912",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 1,
  gasoptions: false,
  referral: false,
  maxFeePerGas: 0.01,
  gaslimit: null,
  klanmode: true,
  testnet: false,
  wrap: false,
  excess: "", 
  website: "battledogs.fun/manta",
  bestover: "10",
  ecosystem: "Manta"
};

const networkData3 = {
  networkid: 3,
  rpc: "https://rpc.scroll.io",
  chainId: 534352,
  hex: "0x82750",
  theme: "moccasin",
  NATIVE: COINTOKEN,
  tokenA: "SCR",
  tokenB: "GAME",
  coin: 'ETH',
  battledogaddress: "0x125176CfAa1Cc92a0cBaaEEC272fE6d6f887729a",
  nativeaddress: "0xd29687c813D741E2F938F4aC377128810E217b1b",
  gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
  popaddress: "0xaD69529505772F2b4Db697f2470e0b069B4cf792",
  harvesteraddress: "0xAc9Fbe0619FD3E63D5111721De818FDB53Baa6ff",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 3,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: true,
  testnet: false,
  wrap: false, 
  excess: "", 
  website: "battledogs.fun/scroll",
  bestover: "100",
  ecosystem: "Scroll"
};


const networkData4 = {
  networkid: 4,
  rpc: "https://mainnet-rpc.swanchain.org",
  chainId: 254,
  hex: "0xfe",
  theme: "dodgerblue",
  NATIVE: ARBITRUM,
  tokenA: "SWANC",
  tokenB: "GAME",
  coin: 'ETH',
  battledogaddress: "0xda405cb8Bd7e61A926D4Be2f3Cb5BB24Fea8f43b",
  nativeaddress: "0xAF90ac6428775E1Be06BAFA932c2d80119a7bd02",
  gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
  popaddress: "0x96F2B371D800bf32BB89DAd05b61B380030030A5",
  harvesteraddress: "0xaB803C683000C3Ab8836477B0e2BDC45C20139d1",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 2,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: true,
  testnet: false,
  wrap: false, 
  excess: "", 
  website: "battledogs.fun/swan",
  bestover: "20",
  ecosystem: "Swan"
};

const networkData5 = {
  networkid: 5,
  rpc: "https://neon-proxy-mainnet.solana.p2p.org",
  chainId: 245022934,
  hex: "0xE9AC0D6",
  theme: "magenta",
  NATIVE: WNEON,
  tokenA: "WNEON",
  tokenB: "GAME",
  coin: 'NEON',
  battledogaddress: "0x9996493807daea0111FD55D4c46594F8d931C857",
  nativeaddress: "0x202C35e517Fa803B537565c40F0a6965D7204609",
  gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
  popaddress: "0xEA8c88D0D10449FaF6F1f2f00F09590E400De23B",
  harvesteraddress: "0x96F2B371D800bf32BB89DAd05b61B380030030A5",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 3,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: true,
  testnet: false,
  wrap: true,
  excess: "",
  website: "battledogs.fun/neon-solana",
  bestover: "10",
  ecosystem: "Neon"
};

const networkData6 = {
  networkid: 6,
  rpc: "https://rpc.mantle.xyz",
  chainId: 5000,
  hex: "0x1388",
  theme: "blanchedalmond",
  NATIVE: MANTLE,
  tokenA: "WMNT",
  tokenB: "GAME",
  coin: 'MANTLE',
  battledogaddress: "0x0E0455F4544E722537e9860699384d9CeE1b4892", 
  nativeaddress: "0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8",
  gameaddress: "0xDA171aa2ABD7F9245aE1388De59d86E05daDa764",
  popaddress: "0x70EaE2857D4901f6150d0409bAcddEFdd9d8fEb5",
  harvesteraddress: "0xDeB4316DeFC86A53fE465207825D987CD2F636a4",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 2,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: true,
  testnet: false,
  wrap: true,
  excess: "", 
  website: "battledogs.fun/mantle",
  bestover: "200",
  ecosystem: "Mantle"
};
   
const networkData7 = {
  networkid: 7,
  rpc: "https://rpc.blaze.soniclabs.com",
  chainId: 57054,
  hex: "0xDEDE",
  theme: "darkorange",
  NATIVE: COINTOKEN,
  tokenA: "RUBY",
  tokenB: "GAME",
  coin: 'S',
  battledogaddress: "0xaB803C683000C3Ab8836477B0e2BDC45C20139d1",
  nativeaddress: "0x75190d6e62B8984b987B2336fD10552eD0e6a538",
  gameaddress: "0xda405cb8Bd7e61A926D4Be2f3Cb5BB24Fea8f43b",
  popaddress: "0xF1B993e57bDF375B331456dF9Bde4c4506637f89",
  harvesteraddress: "0x96F2B371D800bf32BB89DAd05b61B380030030A5",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 10**12,
  decimals: 10**18,
  tofixed: 3,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: true,
  testnet: true,
  wrap: false, 
  excess: "", 
  website: "battledogs.fun/sonic",
  bestover: "10",
  ecosystem: "Sonic"
};

const networkData8 = {
  networkid: 8,
  rpc: "https://mainnet.base.org",
  chainId: 8453,
  hex: "0x2105",
  theme: "dodgerblue",
  NATIVE: USDC,
  tokenA: "USDC",
  tokenB: "GAME",
  coin: 'ETH',
  battledogaddress: "0xb6F88E2a37238c46123d780E2BE1bebe0c8f68ab",
  nativeaddress: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  gameaddress: "0xB55Be259b51CF024ddCeBdB3D5f4851Ff0156Cd8",
  popaddress: "0xcb4Ce71058aFe5b0E5d05a42f78099cc5f74c829",
  harvesteraddress: "0x52198683CE0Fa2601844dfb9945e3B848006666c",
  lpaddress: "",
  lpharvesteraddress: "",
  reducer: 1,
  decimals: 10**6,
  tofixed: 3,
  gasoptions: false,
  referral: false, 
  maxFeePerGas: 0, 
  gaslimit: null,
  klanmode: true,
  testnet: false,
  wrap: false, 
  excess: "", 
  website: "battledogs.fun/base",
  bestover: "10",
  ecosystem: "Base"
  };

  const networkData9 = {
    networkid: 9,
    rpc: "https://rpc.ankr.com/core",
    chainId: 1116,
    hex: "0x45c",
    theme: "orange",
    NATIVE: CORE,
    tokenA: "WCORE",
    tokenB: "GAME",
    coin: 'CORE',
    battledogaddress: "0x20c4bfB93870963b86EB35d27CeD4c385b848836",
    nativeaddress: "0x191e94fa59739e188dce837f7f6978d84727ad01",
    gameaddress: "0x21ba5Fca1159637121A6247aE32Ce000d231c527",
    popaddress: "0x4B0fAB9588D9f459f0cd41b9845303eEa031277C",
    harvesteraddress: "0x5038634f8f6c11B59Cd304fc72522f66c838Fd85",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 3,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: true,
    excess: "", 
    website: "battledogs.fun/core",
    bestover: "100",
    ecosystem: "Core"
  };
  
  const networkData10 = {
    networkid: 10,
    rpc: "https://nova.arbitrum.io/rpc",
    chainId: 42170,
    hex: "0xa4ba",
    theme: "skyblue",
    NATIVE: ARBITRUM,
    tokenA: "ARB",
    tokenB: "GAME",
    coin: 'ETH',
    battledogaddress: "0x717341d7CbF88684d58F2a43F7951eC33fcca4b6",
    nativeaddress: "0xf823C3cD3CeBE0a1fA952ba88Dc9EEf8e0Bf46AD",
    gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
    popaddress: "0x6A2a0171c4a0709d837f0e7d59714720b87a8C18",
    harvesteraddress: "",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 2,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: false,
    excess: "", 
    website: "battledogs.fun/arbitrum",
    bestover: "20",
    ecosystem: "Arbitrum"
  };

  
    
  const networkData11 = {
    networkid: 11,
    rpc: "https://rpc.ankr.com/bsc",
    chainId: 56,
    hex: "0x38",
    theme: "gold",
    NATIVE: COINTOKEN,
    tokenA: "BABYDOGE",
    tokenB: "GAME",
    coin: 'BNB',
    battledogaddress: "0x0313E8f8e23ecBCC12CcFfcBDDf1f8C6f2946cC4",
    nativeaddress: "0xc748673057861a797275cd8a068abb95a902e8de",
    gameaddress: "0x1398f24881056318097D6F00652697dde50aaa93",
    popaddress: "0x1f1988091f8cc8fd4c3a27813960524d4b92e699",
    harvesteraddress: "0x090aDC41894788616e749766b72194a5A953DE4A",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**15,
    decimals: 10**9,
    tofixed: 0,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    excess: "Billion",
    website: "battledogs.fun/bsc",
    bestover: "10 Billion",
    ecosystem: "BSC"
  };  
    
  const networkData12 = {
    networkid: 12,
    rpc: "https://exchainrpc.okex.org",
    chainId: 66,
    hex: "0x42",
    theme: "white",
    NATIVE: OKX,
    tokenA: "OKB",
    tokenB: "GAME",
    coin: 'OKT',
    battledogaddress: "0x027F55011B88743469e6ebF396cdF25a0B94A70F",
    nativeaddress: "0xdF54B6c6195EA4d948D03bfD818D365cf175cFC2",
    gameaddress: "0xA857244EE4250E83c2aF7Dcf62e673c2fdE38798",
    popaddress: "0xA4cc3e418e359617C885119c6DC7a4646d3A3406",
    harvesteraddress: "0xF3C6865A8C9D69C4c694E5CF7A8bB2D687BF00F4",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 6,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false, 
    wrap: false,
    excess: "", 
    website: "battledogs.fun/okx",
    bestover: "1",
    ecosystem: "Okx"
  };  
  
  const networkData13 = {
    networkid: 13,
    rpc: "https://sepolia.unichain.org",
    chainId: 1301,
    hex: "0x515",
    theme: "pink",
    NATIVE: WETH,
    tokenA: "WETH",
    tokenB: "GAME",
    coin: 'ETH',
    battledogaddress: "0xaB803C683000C3Ab8836477B0e2BDC45C20139d1",
    nativeaddress: "0x4200000000000000000000000000000000000006",
    gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
    popaddress: "0x96F2B371D800bf32BB89DAd05b61B380030030A5",
    harvesteraddress: "0x1Fa03219ff837C1Dfc629c5A8F834E47594fa1fd",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 6,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: true,
    wrap: true, 
    excess: "",
    website: "battledogs.fun/unichain",
    bestover: "0.4",
    ecosystem: "Unichain"
  };
  
  const networkData14 = {
    networkid: 14,
    rpc: "https://rpc.pulsechain.com",
    chainId: 369,
    hex: "0x171",
    theme: "hotpink",
    NATIVE: PULSECHAIN,
    tokenA: "WPLS",
    tokenB: "GAME",
    coin: 'PLS',
    battledogaddress: "0xC568d0fbA2db0b40521420e03b627e0E72E3932A",
    nativeaddress: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    gameaddress: "0xA7a0a86D72e0B28c6021355558565AF308Ab2537",
    popaddress: "0x84a47AE8A966042716c3e007A22ad191625A3ECd",
    harvesteraddress: "0xE9817019F6394B2cc91d3cB2b36531216aBC7fa7",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 2,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: true, 
    excess: "",
    website: "battledogs.fun/pulsechain",
    bestover: "1 mil",
    ecosystem: "Pulsechain"
  };
  
  const networkData15 = {
    networkid: 15,
    rpc: "https://rpc.linea.build",
    chainId: 59144,
    hex: "0xe708",
    theme: "whitesmoke",
    NATIVE: USDC,
    tokenA: "USDC",
    tokenB: "GAME",
    coin: 'ETH',
    battledogaddress: "0x8c4D3577189284fD67e9e9A8F015b69753d4DE2e",
    nativeaddress: "0x176211869cA2b568f2A7D4EE941E073a821EE1ff",
    gameaddress: "0x32F6101795Ca1fFf884083783539fA6047089Ea1",
    popaddress: "0x1e0507134435D052E85467bBcD652e3a09763ACc",
    harvesteraddress: "0x24D6e4d3D3A225173d67f4EFA4E5f93445608334",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 1,
    decimals: 10**6,
    tofixed: 3,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: false,
    excess: "", 
    website: "battledogs.fun/linea",
    bestover: "100",
    ecosystem: "Linea"
  };

  const networkData16 = {
    networkid: 16,
    rpc: "https://rpc.minato.soneium.org/",
    chainId: 1946,
    hex: "0x79A",
    theme: "aquamarine",
    NATIVE: WETH,
    tokenA: "WETH",
    tokenB: "GAME",
    coin: 'ETH',
    battledogaddress: "0xda405cb8Bd7e61A926D4Be2f3Cb5BB24Fea8f43b",
    nativeaddress: "0x4200000000000000000000000000000000000006",
    gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
    popaddress: "0x397F4F56Ac8B47170D5Ed13C29d9Bb3dE119C9A2",
    harvesteraddress: "0xE9F46CBB80d809cD4E9521F456b799EFA432192e",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 6,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: true,
    wrap: true, 
    excess: "",
    website: "battledogs.fun/soneium",
    bestover: "0.4",
    ecosystem: "Soneium"
  };

  const networkData17 = {
    networkid: 17,
    rpc: "https://rpc.blast.io",
    chainId: 81457,
    hex: "0x13E31",
    theme: "yellow",
    NATIVE: COINTOKEN,
    tokenA: "USDB",
    tokenB: "GAME",
    coin: 'ETH',
    battledogaddress: "0xad69529505772f2b4db697f2470e0b069b4cf792",
    nativeaddress: "0x4300000000000000000000000000000000000003",
    gameaddress: "0x24C89D67d1C8B569fFe564b8493C0fbD1f55d7F7",
    popaddress: "0x028780a7ddc6791445b60a62e3cc3e90180360b9",
    harvesteraddress: "0x46909f03e8651d60Cbdaa3dCf66526c9b12722Fc",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 2,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false, 
    wrap: false,
    excess: "", 
    website: "battledogs.fun/blast",
    bestover: "10",
    ecosystem: "Blast"
  }
  
  const networkData18 = {
    networkid: 18,
    rpc: "https://opbnb-mainnet-rpc.bnbchain.org",
    chainId: 204,
    hex: "0xcc",
    theme: "goldenrod",
    NATIVE: OPBNB,
    tokenA: "WBNB",
    tokenB: "GAME",
    coin: 'BNB',
    battledogaddress: "0x47E1eE84AbEb638F4BB7EDce51EF217997a8bDAF",
    nativeaddress: "0x4200000000000000000000000000000000000006",
    gameaddress: "0x4D61761E75BB6C5800923D3986c8563a19f1d044",
    popaddress: "0x01c79920905D6914eeBd43f91bC8f32B1518221f",
    harvesteraddress: "0xd0Dc4fb586E66901E1F612cda9D49F9Ccf9c3FB0",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 6,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: true,
    excess: "", 
    bestover: "0.5",
    website: "battledogs.fun/opbnb",
    ecosystem: "OPBNB"
  };
    
  const networkData19 = {
    networkid: 19,
    rpc: "https://rpc.ankr.com/polygon",
    chainId: 137,
    hex: "0x89",
    theme: "purple",
    NATIVE: POLYGON,
    tokenA: "WMATIC",
    tokenB: "GAME",
    coin: 'MATIC',
    battledogaddress: "0x0e174a13c52d93f13c6416b5617820dee476a566",
    nativeaddress: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    gameaddress: "0x9BE3fb84c55128c2B213eD649A0531cB78b17169",
    popaddress: "0xba132C6E6BA97D248146704dfD23092Bd8637979",
    harvesteraddress: "0x32F6101795Ca1fFf884083783539fA6047089Ea1",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 6,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: true, 
    excess: "", 
    website: "battledogs.fun/polygon",
    bestover: "100",
    ecosystem: "Polygon"
  };  
    
  const networkData20 = {
    networkid: 20,
    rpc: "https://testnet-api.applayer.com/",
    chainId: 75338,
    hex: "0x1264a",
    theme: "limegreen",
    NATIVE: COINTOKEN,
    tokenA: "APPL",
    tokenB: "GAME",
    coin: 'APP',
    battledogaddress: "0xe81B94aB3f3e79F420A9E9664e7D274634464965",
    nativeaddress: "0x66D0D148b6AA4B3B3e74988F936D4dD6205011A9",
    gameaddress: "0x744029E4aaC47568b7E7014EF27064639e2ad08d",
    popaddress: "0x74b79dbda6A1644a1c6bDE56da707C0D84C56d80",
    harvesteraddress: "0xF00Ed50ACb942f20887490dDab81A4222d1eC45B",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 2,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: true,
    wrap: false,  
    excess: "",
    website: "battledogs.fun/applayer",
    bestover: "10",
    ecosystem: "Applayer"
  };
  
  const networkData21 = {
    networkid: 21,
    rpc: "https://rpc.zkfair.io",
    chainId: 42766,
    hex: "0xA70E",
    theme: "crimson",
    NATIVE: COINTOKEN,
    tokenA: "ZKF",
    tokenB: "GAME",
    coin: 'USDC',
    battledogaddress: "0x96f2b371d800bf32bb89dad05b61b380030030a5",
    nativeaddress: "0x1cd3e2a23c45a690a18ed93fd1412543f464158f",
    gameaddress: "0x24c89d67d1c8b569ffe564b8493c0fbd1f55d7f7",
    popaddress: "0x053f7f59c25DdfB1E2173F143237bf7720BE3924",
    harvesteraddress: "0x2dDc80b99DEFD3CF6BbC09f8a5aAeD601CBa625a",
    lpaddress: "",
    lpharvesteraddress: "",
    reducer: 10**12,
    decimals: 10**18,
    tofixed: 2,
    gasoptions: false,
    referral: false, 
    maxFeePerGas: 0, 
    gaslimit: null,
    klanmode: true,
    testnet: false,
    wrap: false,
    excess: "", 
    website: "battledogs.fun/zkfair",
    bestover: "1000",
    ecosystem: "zkFAIR"
  };

export const networkDataArray = [
  networkData1,
  networkData2,
  networkData3,
  networkData4,
  networkData5,
  networkData6,
  networkData7,
  networkData8,
  networkData9,
  networkData10,
  networkData11,
  networkData12,
  networkData13,
  networkData14,
  networkData15,
  networkData16,
  networkData17,
  networkData18, 
  networkData19,
  networkData20
]

  export { contractData, networkData1, networkData2, networkData3, networkData4, networkData5, networkData6,networkData7, networkData8, 
    networkData9, networkData10, networkData11, networkData12, networkData13, networkData14, networkData15, networkData16, networkData17,
    networkData18, networkData19, networkData20};
