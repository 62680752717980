import { networkDataArray } from '../../tools/networkData';

// Action Types
export const FETCH_NETWORK_REQUEST = 'FETCH_NETWORK_REQUEST';
export const FETCH_NETWORK_SUCCESS = 'FETCH_NETWORK_SUCCESS';
export const FETCH_NETWORK_FAILURE = 'FETCH_NETWORK_FAILURE';
export const UPDATE_NETWORK_INDEX = 'UPDATE_NETWORK_INDEX';

// Action Creators
export const fetchNetworkRequest = () => ({
  type: FETCH_NETWORK_REQUEST
});

export const fetchNetworkSuccess = (networkData) => ({
  type: FETCH_NETWORK_SUCCESS,
  payload: networkData
});

export const fetchNetworkFailure = (error) => ({
  type: FETCH_NETWORK_FAILURE,
  payload: error
});

export const updateNetworkIndex = (index) => ({
  type: UPDATE_NETWORK_INDEX,
  payload: index
});

// Thunk action to fetch network data based on counter
export const fetchNetworkData = (counter) => {
  return (dispatch) => {
    dispatch(fetchNetworkRequest());
    try {
      // Get network data from networkDataArray based on counter
      const networkData = networkDataArray[counter];
      if (networkData) {
        dispatch(fetchNetworkSuccess(networkData));
        dispatch(updateNetworkIndex(counter));
      } else {
        dispatch(fetchNetworkFailure('Invalid network index'));
      }
    } catch (error) {
      dispatch(fetchNetworkFailure(error.message));
    }
  };
};
